import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/places-and-things.json';

const thumbnails = getThumbnailData(photoNames);

const PlacesAndThings: React.FC = () => {
  return (
    <Page title="Places and Things">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="places-and-things"
      />
    </Page>
  );
}

export default PlacesAndThings;
