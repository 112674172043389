import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import cloudinaryData from '../data/cloudinary.json';
import '../fonts/fontello/css/fontello.css';
import { usePhotoList } from "./PhotoGallery";

const getPhotoUrlByName = (name?: string) => {
  const photoData = cloudinaryData.resources.find(d => d.public_id === name);
  return photoData?.url;
};

const PhotoDetail: React.FC<any> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const parentPath = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
  const params = useParams();
  const photoNameLarge = params.photoId?.replace('-SM', '-LG');
  const { photoList } = usePhotoList();
  const currentPhotoIndex = photoList.indexOf(params.photoId!);
  let previousPhoto: string | undefined;
  let nextPhoto: string | undefined;
  if (currentPhotoIndex > 0) {
    previousPhoto = photoList[currentPhotoIndex - 1];
  }
  if (currentPhotoIndex < photoList.length - 1) {
    nextPhoto = photoList[currentPhotoIndex + 1];
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      console.log(e.key);
      if(previousPhoto && e.key === 'ArrowLeft') {
        navigate(parentPath + '/' + previousPhoto, { replace: true });
      } else if(nextPhoto && e.key === 'ArrowRight') {
        navigate(parentPath + '/' + nextPhoto, { replace: true })
      }
    }

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [params.photoId]);
  
  return (
    <div 
      className="photo-detail fixed top-0 left-0 z-30 h-screen w-full md:ml-64 bg-white"
      style={{width: 'calc(100% - 16rem)'}}
    >
      <Link 
        className="text-3xl absolute top-8 right-8 z-10 translate-x-1/2 -translate-y-1/2" 
        to=".."
      >
        <i className="icon-cancel"></i>
      </Link>
      {previousPhoto && (
        <Link
          className="text-4xl absolute z-10 top-1/2 -translate-y-1/2"
          to={parentPath + '/' + previousPhoto}
        >
          <i className="icon-angle-left"></i>
        </Link>
      )}
      {nextPhoto && (
        <Link
          className="text-4xl absolute z-10 right-8 top-1/2 translate-x-1/2 -translate-y-1/2"
          to={parentPath + '/' + nextPhoto}
        >
          <i className="icon-angle-right"></i>
        </Link>
      )}
      <img 
        className="h-auto p-16 max-h-full max-w-full absolute inset-1/2 -translate-x-1/2 -translate-y-1/2" 
        src={getPhotoUrlByName(photoNameLarge)}
      />
    </div>
  );
}

export default PhotoDetail;
