import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/panoramas.json';

const thumbnails = getThumbnailData(photoNames);

const Panoramas: React.FC = () => {
  return (
    <Page title="Panoramas">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="panoramas"
      />
    </Page>
  );
}

export default Panoramas;

