import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/scapes.json';

const thumbnails = getThumbnailData(photoNames);

const Scapes: React.FC = () => {
  return (
    <Page title="Scapes">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="nature/scapes"
      />
    </Page>
  );
}

export default Scapes;

