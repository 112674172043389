import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/flora.json';

const thumbnails = getThumbnailData(photoNames);

const Flora: React.FC = () => {
  return (
    <Page title="Flora">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="nature/flora"
      />
    </Page>
  );
}

export default Flora;

