import React from 'react';
import Gallery from "react-photo-gallery";
import { Link } from 'react-router-dom';
import Page from "../components/Page";
import { getThumbnailData } from "../components/PhotoGallery";
import PhotoLabelOverlay from "../components/PhotoLabelOverylay";

const photoNamesToCategory: any = {
  'HappyBudgie-SM': { path: 'fauna', label: 'Fauna' },
  'Kabloom-SM': { path: 'flora', label: 'Flora' },
  'SpanishMossFog-SM': { path: 'scapes', label: 'Scapes' }
};
const photoNames = Object.keys(photoNamesToCategory);
const thumbnails = getThumbnailData(photoNames);

const Nature: React.FC = () => {
  const imageRenderer: React.FC<any> = ({ key, photo }) => (
    <Link 
      key={key} 
      className="relative hover:scale-95 transition duration-300"
      to={`${photoNamesToCategory[photo.name].path}`}
    >
      <img
        src={photo.src}
        height={photo.height}
        width={photo.width}
        style={{margin: '2px'}}
      />
      <PhotoLabelOverlay width={photo.width}>{photoNamesToCategory[photo.name].label}</PhotoLabelOverlay>
    </Link>
  );
  return (
    <Page title="Nature">
      <Gallery photos={thumbnails} renderImage={imageRenderer}/>
    </Page>
  );
}

export default Nature;
