import React from 'react';
import Gallery from "react-photo-gallery";
import { Link, Outlet, useOutletContext } from "react-router-dom";
import cloudinaryData from '../data/cloudinary.json';

export interface Thumbnail {
  src: string,
  width: number,
  height: number,
  name: string,
}

interface PhotoGalleryProps {
  photoNames: string[],
  thumbnails: Thumbnail[],
  parentPath: string
}

const PhotoGallery: React.FC<PhotoGalleryProps> = ({
  photoNames = [],
  thumbnails = [],
  parentPath
}) => {
  const [photoList, setPhotoList] = React.useState(photoNames);
  const imageRenderer: React.FC<any> = ({ key, photo }) => (
    <Link key={key} className="relative hover:scale-95 transition duration-300" to={`/${parentPath}/${photo.name}`}>
      <img
        src={photo.src}
        height={photo.height}
        width={photo.width}
        style={{margin: '2px'}}
      />
    </Link>
  );

  return (
    <div>
      <Gallery photos={thumbnails} renderImage={imageRenderer}/>
      <Outlet context={{ photoList }}/>
    </div>
  );
};

type PhotoListContextType = { photoList: string[] };

export const usePhotoList = () => {
  return useOutletContext<PhotoListContextType>();
};

export const getThumbnailData = (photoNames: string[]) => {
  const thumbnails: Thumbnail[] = [];
  photoNames.forEach(name => {
    const cloudPhoto = cloudinaryData.resources.find(d => d.public_id === name);
    if (cloudPhoto) {
      thumbnails.push({
        src: cloudPhoto.url,
        width: cloudPhoto.width,
        height: cloudPhoto.height,
        name: cloudPhoto.public_id
      });
    }
  });
  return thumbnails;
};

export default PhotoGallery;
