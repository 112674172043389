import React from 'react';
import Gallery from "react-photo-gallery";
import { Link } from 'react-router-dom';
import Page from "../components/Page";
import { getThumbnailData } from "../components/PhotoGallery";
import PhotoLabelOverlay from "../components/PhotoLabelOverylay";

const photoNamesToCategory: any = {
  'Snail-SM': { path: 'nature', label: 'Nature' },
  'ATLSkylineSunset-SM': { path: 'places-and-things', label: 'Places and Things' },
  'YellowMichelle-SM': { path: 'people', label: 'People' },
  'FilmSailboats-SM': { path: 'film', label: 'Film' },
  'LabrynthPano-LG': { path: 'panoramas', label: 'Panoramas' },
};
const photoNames = Object.keys(photoNamesToCategory);
const thumbnails = getThumbnailData(photoNames);

const Home: React.FC = () => {
  const imageRenderer: React.FC<any> = ({ key, photo }) => (
    <Link 
      key={key} 
      className="relative hover:scale-95 transition duration-300"
      to={`/${photoNamesToCategory[photo.name].path}`}
    >
      <img
        src={photo.src}
        height={photo.height}
        width={photo.width}
        style={{margin: '2px'}}
      />
      <PhotoLabelOverlay width={photo.width}>{photoNamesToCategory[photo.name].label}</PhotoLabelOverlay>
    </Link>
  );
  return (
    <Page title="ShutterBerg Photography">
      <Gallery photos={thumbnails} renderImage={imageRenderer}/>
    </Page>
  );
}

export default Home;
