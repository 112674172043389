import React, { useEffect } from 'react';
import classNames from "classnames";

const ExternalLink: React.FC<any> = (props) => {
  return (
    <a 
      className={classNames("text-iris hover:text-duke-blue transition duration-300", props.className)}
      {...props}
    />
  );
}

export default ExternalLink;
