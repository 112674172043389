import React from 'react';
import ExternalLink from "../components/ExternalLink";
import Page from "../components/Page";

const About: React.FC = () => {
  return (
    <Page title="About My Photography" hasFooter>
      <div className="container md:max-w-screen-md">
        <p className="mb-3">
          I began teaching myself photography in 2013. Three weeks after I bought my camera, I went to South Africa where I discovered the joy of photographing nature. I was captivated by all aspects of the wildlife there, zooming in on every detail head to toe and zooming out to capture animals in the unique context of their natural surroundings.
        </p>
        <p className="mb-3">
          When I returned to school in Florida, I rediscovered its landscape through my lens. I began to notice the way that light would shine through the Spanish moss, the patterns that would reflect in the rippling water, and the way that the world would glow in the midst of every one-of-a-kind sunset. I particularly grew to love the birds of Florida, the unique shapes they created with their bodies and the way these shapes played with the structures in their surrounding environments. 
        </p>
        <p className="mb-3">
          Today I continue to see the world as if I'm looking through my camera, always picking up on subtle patterns in day-to-day settings and searching for the allure of nature, even in urban environments. Thus I try to have a camera on me at all times, whether it's my Canon DSLR or a camera phone, and I strive to capture and share each moment as I see it.
        </p>
      </div>
    </Page>
  );
}

export default About;
