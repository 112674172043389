import React, { useEffect } from 'react';
import ExternalLink from "./ExternalLink";
import Sidebar from './Sidebar';

interface PageProps {
  title?: string;
  hasFooter?: boolean;
  children?: React.ReactNode;
}

const Page: React.FC<PageProps> = ({ title, hasFooter, children }) => {
  
  useEffect(() => {
    document.title = title ? `${title} | ShutterBerg` : 'ShutterBerg';
  }, [title]);

  return (
    <main>
      <div>
        <Sidebar/>
        <div className="md:ml-64 flex flex-1 flex-col min-h-screen">
          <div className="flex-1 shrink-0 basis-auto md:container p-4 relative">
            {title && <h1 className="text-4xl font-bold mb-8 mt-4">{title}</h1>}
            {children}
          </div>
          {hasFooter && (
            <div className="shrink-0 p-4 text-right">
              Website by <ExternalLink href="https://codonn.com" target="_blank">codonn</ExternalLink>
            </div>
          )}
        </div>
      </div>
    </main>
  );
}

export default Page;
