import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/film.json';

const thumbnails = getThumbnailData(photoNames);

const Film: React.FC = () => {
  return (
    <Page title="Film">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="film"
      />
    </Page>
  );
}

export default Film;

