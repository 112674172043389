import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PhotoDetail from "./components/PhotoDetail";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Fauna from './pages/Fauna';
import Film from "./pages/Film";
import Home from './pages/Home';
import Nature from "./pages/Nature";
import Panoramas from "./pages/Panoramas";
import People from "./pages/People";
import PlacesAndThings from "./pages/PlacesAndThings";
import Splash from './pages/Splash';
import Flora from "./pages/Flora";
import Scapes from "./pages/Scapes";

const App: React.FC = () => {
  return (
    <div className="app">
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/home" element={<Home />} />
        <Route path="/nature" element={<Nature />} />
        <Route path="/nature/flora" element={<Flora />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/nature/fauna" element={<Fauna />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/nature/scapes" element={<Scapes />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/places-and-things" element={<PlacesAndThings />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/people" element={<People />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/film" element={<Film />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/panoramas" element={<Panoramas />}>
          <Route path=":photoId" element={<PhotoDetail />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<Navigate to="/home" />} />
      </Routes>
    </div>
  );
}

export default App;
