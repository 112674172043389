import React from 'react';

interface PhotoLabelOverlayProps {
  width?: number;
  children?: React.ReactNode;
}

const PhotoLabelOverlay: React.FC<PhotoLabelOverlayProps> = ({ width, children }) => {
  return (
    <>
      <div 
        className="shb-overlay-bg inline-block absolute bottom-0 h-24 transition duration-300"
        style={{
          width: width ? width + 'px' : '100%',
          background: 'linear-gradient(to bottom, transparent 0%, black 100%)',
          margin: '2px'
        }}
      ></div>
      <div 
        className="inline-block absolute bottom-4 w-full text-white text-center text-3xl font-bold"
      >
        {children}
      </div>
    </>
  );
}

export default PhotoLabelOverlay;
