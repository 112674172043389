import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/fauna.json';

const thumbnails = getThumbnailData(photoNames);

const Fauna: React.FC = () => {
  return (
    <Page title="Fauna">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="nature/fauna"
      />
    </Page>
  );
}

export default Fauna;

