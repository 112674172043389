import React from 'react';
import ExternalLink from "../components/ExternalLink";
import Page from "../components/Page";

const Contact: React.FC = () => {
  return (
    <Page title="Contact" hasFooter>
      <div className="container md:max-w-screen-md">
        <p className="mb-3">
          Thank you for taking the time to enjoy my photography and if you have any inquiries about any particular photos or interest in purchasing prints, please feel free to contact me.
        </p>
        <p className="mb-3 text-xl">
          Rachel Feinberg
        </p>
        <p className="mb-3">
          <i className="icon-mail-alt mr-2"></i>rrfeinberg@gmail.com
        </p>
        <ExternalLink href="https://www.instagram.com/shutter.berg/" target="_blank">
          <i className="icon-instagram mr-2"></i>ShutterBerg on Instagram
        </ExternalLink>
      </div>
    </Page>
  );
}

export default Contact;
