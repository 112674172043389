import React from 'react';
import Page from '../components/Page';
import PhotoGallery, { getThumbnailData } from "../components/PhotoGallery";
import photoNames from '../data/people.json';

const thumbnails = getThumbnailData(photoNames);

const People: React.FC = () => {
  return (
    <Page title="People">
      <PhotoGallery
        photoNames={photoNames}
        thumbnails={thumbnails}
        parentPath="people"
      />
    </Page>
  );
}

export default People;
