import classNames from "classnames";
import React, { useState } from 'react';
import { Link, NavLink, NavLinkProps } from 'react-router-dom';

const SidebarLink: React.FC<NavLinkProps> = (props) => (
  <NavLink
    {...props}
    className={classNames("inline-block text-lg font-bold mb-4 hover:text-iris transition duration-500", props.className)}
  />
);

const Sidebar: React.FC = () => {
  const [isHiddenMobile, setIsHiddenMobile] = useState(true);

  const handleMobileMenuClick = () => {
    console.log('click');
    setIsHiddenMobile(!isHiddenMobile);
  };

  return (
    <>
      <i 
        className={classNames("text-2xl absolute top-4 right-4 bg-white rounded z-20 md:hidden", {
          'icon-menu': isHiddenMobile,
          'icon-cancel': !isHiddenMobile
        })} 
        onClick={() => handleMobileMenuClick()}
      />
      <aside 
        className={classNames("fixed left-0 z-20 md:translate-x-0 transition duration-300 bg-slate-200 border-r border-slate-300 w-64 h-full p-4", {
          '-translate-x-full': isHiddenMobile
        })}>
        <Link to="/home" className="text-lg font-bold block -m-4 mb-4 p-4 border-b border-slate-300">
          <i className="icon-camera mr-1"></i>ShutterBerg
        </Link>
        <ul>
          <li>
            <SidebarLink to="/nature">Nature</SidebarLink>
          </li>
          <li>
            <SidebarLink to="/places-and-things">Places and Things</SidebarLink>
          </li>
          <li>
            <SidebarLink to="/people">People</SidebarLink>
          </li>
          <li>
            <SidebarLink to="/film">Film</SidebarLink>
          </li>
          <li>
            <SidebarLink className="mb-0" to="/panoramas">Panoramas</SidebarLink>
          </li>
          <li className="-m-4 mt-4 p-4 border-t border-slate-300">
            <SidebarLink to="/about">About</SidebarLink>
          </li>
          <li>
            <SidebarLink to="/contact">Contact</SidebarLink>
          </li>
        </ul>
      </aside>
    </>
  );
}

export default Sidebar;
