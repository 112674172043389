import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Splash: React.FC = () => {
  
  useEffect(() => {
    document.title = 'ShutterBerg Photography';
  }, []);

  return (
    <main className="bg-splash bg-cover bg-center block h-full w-full text-white">
      <div className="text-center absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
        <Link to="/home"><h1 className="text-5xl mb-3">ShutterBerg Photography</h1></Link>
        <p className="text-xl mb-5">by Rachel Feinberg</p>
        <Link to="/home" className="inline-block border text-2xl py-4 px-12 hover:bg-white hover:text-black transition duration-300 ">Enter</Link>
      </div>
    </main>
  );
}

export default Splash;
